<template lang="pug">
div
  .d-flex.flex-column.w-100.align-items-center.startpage-custom-campaign.position-relative.mb-5.pb-3.pb-md-4(v-if="trips && trips.departures && trips.departures.length > 0")
    img.w-100.mb-4.crop-right-increase-height.cursor-pointer(
      :alt="$t('startpageCustomCampaign_title')"
      src="https://res.cloudinary.com/rolfs-flyg-buss/image/upload/v1725366464/banners/hostensfavoriter_cpb8tu.jpg"
      background-color="white"
      fit="contain"
      @click="goToEventsPage"
    )
    //- LazyResponsiveImage.w-100.mb-4.small-banner(
    //-     :alt="$t('startpageCustomCampaign_title')"
    //-     image="banners/sommarturne-2024"
    //-     background-color="white"
    //-     fit="contain"
    //-   )
        //- slot(name="title")
    h3.d-flex.flex-column.h-100.w-100.align-items-center.justify-content-center.text-align-center(data-i18n="startpageCustomCampaign_title") {{ $t('startpageCustomCampaign_title') }}
    h5.text-break-pre-line.mb-3.font-weight-semibold
      .d-inline-flex.flex-row.align-items-center.mb-3.mt-3.mt-sm-0
        hr
        span.mx-3.text-center(data-i18n="startpageCustomCampaign_subtitle") {{ $t('startpageCustomCampaign_subtitle') }}
        hr

    p.text-align-center.mb-5(data-i18n="startpageCustomCampaign_ingress") {{ $t('startpageCustomCampaign_ingress') }}

    Swiper.w-100(
      ref="swiper"
      dots-type="dark"
      rewind
      only-render-visible-slides
      prevent-swiper-update
      constant-display-navigation
      navigation-position="bottom"
    )
      .grid-container(
        v-for="(pageTrips, idx) in paginatedTrips"
        :key="idx"
      )
        RolfsCustomCampaignCard.grid-item(
          v-for="(trip, idc) in pageTrips"
          :key="idc"
          :date="trip.date"
          :image="trip.main_picture_cloudinary"
          :name="trip.tripname"
          :original-price="trip.original_price_SEK"
          :price="trip.price_SEK"
          :link="trip.triptypeurl"
        )

      template(#navigation="{ slideNext, slidePrev, activeSlideIndex, showSlideIndex }")
          .pagination.mt-5
            button.bullet(
              v-for="(page, index) in numberOfPages"
              :key="index"
              :class="{ active: activeSlideIndex === index }"
              @click="showSlideIndex(index)"
            )

          .nav-buttons.w-100.position-absolute
            button.prev-button.rounded-circle.p-0.z-3.d-flex.justify-content-center.align-items-center(
              v-if="activeSlideIndex > 0"
              @click="slidePrev"
            )
              FaSharpSolidChevronLeft.small-chevron.d-block(
                height="18"
                width="18"
                fill="white"
              )
              FaSharpRegularChevronLeft.big-chevron.d-block(
                height="25"
                width="25"
                fill="#b8bfcc"
              )
            button.next-button.rounded-circle.p-0.z-3.d-flex.justify-content-center.align-items-center(
              v-if="activeSlideIndex < numberOfPages - 1"
              @click="slideNext"
            )
              FaSharpSolidChevronRight.small-chevron.d-block(
                height="18"
                width="18"
                fill="white"
              )
              FaSharpRegularChevronRight.big-chevron.d-block(
                height="25"
                width="25"
                fill="#b8bfcc"
              )
</template>

<script setup>
import FaSharpSolidChevronLeft from '@fortawesome/fontawesome-pro/svgs/sharp-solid/chevron-left.svg'
import FaSharpSolidChevronRight from '@fortawesome/fontawesome-pro/svgs/sharp-solid/chevron-right.svg'
import FaSharpRegularChevronRight from '@fortawesome/fontawesome-pro/svgs/sharp-regular/chevron-right.svg'
import FaSharpRegularChevronLeft from '@fortawesome/fontawesome-pro/svgs/sharp-regular/chevron-left.svg'

  const ITEMS_PER_PAGE = ref(8);
  const localeStore = useLocaleStore()
  const calendarStore = useCalendarStore()

  const props = defineProps({
    campaignName: {
      type: String,
      default: ''
    }
  })

  const { error, data } = await useAsyncData(async () => {
    try {
      const { data: campaign } = await apiFetch(`/${localeStore.locale}/campaign/${props.campaignName}`)

      calendarStore.SET_CAMPAIGN_ID(campaign?.id)
      calendarStore.SET_LASTMINUTE(true)
      calendarStore.SET_START_PAGE_CAMPAIGN(true)

      const trips = await calendarStore.getCalendar()

      return {
        trips
      }
    } catch (e) {
      throw new Error('Post not found: ')
    }
  })

  if (error.value) {
    throw createError({
      message: error.value.message,
      statusCode: 404,
      stack: error.value.stack,
      fatal: true
    })
  }

  const { trips } = data.value

  const setItemsPerPage = () => {
    if (window?.innerWidth <= 718) {
      ITEMS_PER_PAGE.value = 1
    } else if (window?.innerWidth <= 1860) {
      ITEMS_PER_PAGE.value = 4
    } else {
      ITEMS_PER_PAGE.value = 8
    }
  };
onMounted(() => {
  window.addEventListener('resize', setItemsPerPage);
  setItemsPerPage()
});

const paginatedTrips = computed(() => {
  return trips && trips.departures ? chunkArray(trips.departures, ITEMS_PER_PAGE.value) : []
})

const numberOfPages = computed(() => trips && trips.departures ? Math.ceil(trips.departures.length / ITEMS_PER_PAGE.value) : 0)

  onBeforeUnmount(() => {
    window.removeEventListener('resize', setItemsPerPage);
    calendarStore.SET_CAMPAIGN_ID(null)
    calendarStore.SET_FORCE_AIRPORT(null)
    calendarStore.SET_LASTMINUTE(false)
    calendarStore.SET_START_PAGE_CAMPAIGN(false)
  })

  const goToEventsPage = () => {
    navigateTo('/kampanj/resor-till-hosten')
  }
</script>

<style lang="scss" scoped>
.startpage-custom-campaign {
  // .small-banner {
  //   height: 86px;
  // }
  h3, h5 {
    font-family: libre baskerville, serif;
  }

  h3 {
    letter-spacing: 0.7px;
  }

  p {
    letter-spacing: 0.34px;
  }

  hr {
    width: 50px;
    background: black;
  }
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  justify-items: center;
  margin-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.flex-gap {
  gap: 1.2em;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 1em;

  .bullet {
    margin: 0 0.5em;
    width: 10px;
    height: 10px;
    border-radius: 50%;

    &.active {
      background: black;
    }
  }
}
.nav-buttons {
  bottom: 2rem;

  button {
    width: 48px;
    height: 48px;
    background: $medium-blue;
  }
  .prev-button {
    position: absolute;
    left: 0;

    &:hover {
      .small-chevron {
        fill: $gray;
      }
    }
  }
  .next-button {
    position: absolute;
    right: 0;

    &:hover {
      .small-chevron {
        fill: $gray;
      }
    }
  }
}
.small-chevron{
    display: block !important;
  }
.big-chevron {
  display: none !important;
}
@media (max-width: 718px) {
  .pagination {
    display: none;
  }
  .nav-buttons{
    bottom: 0;
  }
  .grid-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
    justify-items: center;
    margin-bottom: 1rem;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 580px) {
  .crop-right-increase-height {
    object-fit: cover;
    object-position: left center;
    height: 48px;
  }
  .startpage-custom-campaign {

  hr {
    width: 25px;
    background: black;
  }
}
  .nav-buttons {
    top: calc(50% - 48px);

    button {
      background: $white;
    }
    .next-button {
      right: -24px;

      &:hover {
      .big-chevron {
        fill: black;
      }
    }
    }
    .prev-button {
      left: -24px;

      &:hover {
      .big-chevron {
        fill: black;
      }
    }
    }

  }
  .big-chevron {
    display: block !important;
  }
  .small-chevron{
    display: none !important;
  }
}
</style>